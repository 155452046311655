import router from "../router";

window.onload = function () {
    async function isIncognito() {
        try {
            if ("storage" in navigator && "estimate" in navigator.storage) {
                const { usage, quota } = await navigator.storage.estimate();
                let text = `Using ${usage} out of ${quota} bytes.`;
                if (quota < 120000000) {
                    return ["y", text];
                } else {
                    return ["n", text];
                }
            } else {
                return ["error", ""];
            }
        } catch (e) {
            return ["catch", e];
        }
    }

    function ParseSetCookie() {
        try {
            let id =
                Date.parse(new Date()) + Math.random().toString(36).slice(2);
            document.cookie =
                _ParseUID +
                "=" +
                escape(id) +
                "; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
            return id;
        } catch (e) {
            window.parse_cache_error.setCookie = e.toString();
        }
    }

    function ParseGetCookie(name = _ParseUID) {
        try {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(name + "=");
                if (c_start != -1) {
                    c_start = c_start + name.length + 1;
                    let c_end = document.cookie.indexOf(";", c_start);
                    if (c_end == -1) c_end = document.cookie.length;
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return false;
        } catch (e) {
            window.parse_cache_error.getCookie = e.toString();
        }
    }
    function ParseSetCreateTime() {
        try {
            let d = new Date();
            d.setTime(d.getTime() + 8 * 60 * 60 * 1000);
            window.parse_data.created_at = d.toISOString();
            window.parse_data.date = (window.parse_data.created_at || "").slice(
                0,
                10
            );
        } catch (e) {
            window.parse_cache_error.getTime = e.toString();
        }
    }

    window.parse_arr = [];

    window.ParseSendInfo = function ParseSendInfo() {
        try {
            window.parse_data.downlink = navigator.connection
                ? navigator.connection.downlink
                : 0;
            window.parse_data.effectiveType = navigator.connection
                ? navigator.connection.effectiveType
                : "";
        } catch (e) {
            window.parse_cache_error.getInfo = e.toString();
        }

        try {
            if (!window.analyticInterface) return;
            window.parse_data.jsError = JSON.stringify(
                window.parse_cache_error
            );
            ParseSetCreateTime();
            window.parse_arr.push(window.parse_data);
            let oReq = new XMLHttpRequest();
            oReq.open("POST", window.analyticInterface, true);
            oReq.setRequestHeader(
                "X-Parse-Application-Id",
                "q5jvWMLK5VAn0zVjMVPFSnZYPpbCj2JEAiBpahAC"
            );
            oReq.setRequestHeader("Content-Type", "application/json");
            oReq.send(JSON.stringify(window.parse_data));
            oReq.onerror = function () {
                setTimeout(
                    function (data) {
                        let oReq = new XMLHttpRequest();
                        oReq.open("POST", window.analyticInterface, true);
                        oReq.setRequestHeader(
                            "X-Parse-Application-Id",
                            "q5jvWMLK5VAn0zVjMVPFSnZYPpbCj2JEAiBpahAC"
                        );
                        oReq.setRequestHeader(
                            "Content-Type",
                            "application/json"
                        );
                        oReq.send(data);
                    },
                    1000,
                    JSON.stringify(
                        window.parse_arr[window.parse_arr.length - 1]
                    )
                );
            };
            window.parse_data.buy_product = "";
            window.parse_data.quantity = "";
        } catch (e) {
            window.parse_cache_error.allError = e.toString();
        }
    };

    function ParseBrowserType() {
        try {
            const ua = navigator.userAgent.toLowerCase();
            const testUa = (regexp) => regexp.test(ua);
            const testVs = (regexp) =>
                (ua.match(regexp) || "")
                    .toString()
                    .replace(/[^0-9|_.]/g, "")
                    .replace(/_/g, ".");
            let system = "unknow";
            if (testUa(/windows|win32|win64|wow32|wow64/g)) {
                system = "windows";
            } else if (testUa(/macintosh|macintel/g)) {
                system = "macos";
            } else if (testUa(/x11/g)) {
                system = "linux";
            } else if (testUa(/android|adr/g)) {
                system = "android";
            } else if (testUa(/ios|iphone|ipad|ipod|iwatch/g)) {
                system = "ios";
            }
            window.parse_data.system = system;

            let systemVs = "unknow";
            if (system === "windows") {
                if (testUa(/windows nt 5.0|windows 2000/g)) {
                    systemVs = "2000";
                } else if (testUa(/windows nt 5.1|windows xp/g)) {
                    systemVs = "xp";
                } else if (testUa(/windows nt 5.2|windows 2003/g)) {
                    systemVs = "2003";
                } else if (testUa(/windows nt 6.0|windows vista/g)) {
                    systemVs = "vista";
                } else if (testUa(/windows nt 6.1|windows 7/g)) {
                    systemVs = "7";
                } else if (testUa(/windows nt 6.2|windows 8/g)) {
                    systemVs = "8";
                } else if (testUa(/windows nt 6.3|windows 8.1/g)) {
                    systemVs = "8.1";
                } else if (testUa(/windows nt 10.0|windows 10/g)) {
                    systemVs = "10";
                }
            } else if (system === "macos") {
                systemVs = testVs(/os x [\d._]+/g);
            } else if (system === "android") {
                systemVs = testVs(/android [\d._]+/g);
            } else if (system === "ios") {
                systemVs = testVs(/os [\d._]+/g);
            }
            window.parse_data.systemVs = systemVs;

            let platform = navigator.platform || "unknow";
            if (
                system === "windows" ||
                system === "macos" ||
                system === "linux"
            ) {
                platform = "desktop";
            } else if (
                system === "android" ||
                system === "ios" ||
                testUa(/mobile/g)
            ) {
                platform = "mobile";
            }
            window.parse_data.platform = platform;

            let engine = "unknow";
            let supporter = "unknow";
            if (testUa(/applewebkit/g)) {
                engine = "webkit";
                if (testUa(/edge/g)) {
                    supporter = "edge";
                } else if (testUa(/opr/g)) {
                    supporter = "opera";
                } else if (testUa(/chrome/g)) {
                    supporter = "chrome";
                } else if (testUa(/safari/g)) {
                    supporter = "safari";
                }
            } else if (testUa(/gecko/g) && testUa(/firefox/g)) {
                engine = "gecko";
                supporter = "firefox";
            } else if (testUa(/presto/g)) {
                engine = "presto";
                supporter = "opera";
            } else if (testUa(/trident|compatible|msie/g)) {
                engine = "trident";
                supporter = "iexplore";
            }
            window.parse_data.engine = engine;
            window.parse_data.supporter = supporter;

            let engineVs = "unknow";
            if (engine === "webkit") {
                engineVs = testVs(/applewebkit\/[\d._]+/g);
            } else if (engine === "gecko") {
                engineVs = testVs(/gecko\/[\d._]+/g);
            } else if (engine === "presto") {
                engineVs = testVs(/presto\/[\d._]+/g);
            } else if (engine === "trident") {
                engineVs = testVs(/trident\/[\d._]+/g);
            }
            window.parse_data.engineVs = engineVs;

            let supporterVs = "unknow";
            if (supporter === "chrome") {
                supporterVs = testVs(/chrome\/[\d._]+/g);
            } else if (supporter === "safari") {
                supporterVs = testVs(/version\/[\d._]+/g);
            } else if (supporter === "firefox") {
                supporterVs = testVs(/firefox\/[\d._]+/g);
            } else if (supporter === "opera") {
                supporterVs = testVs(/opr\/[\d._]+/g);
            } else if (supporter === "iexplore") {
                supporterVs = testVs(/(msie [\d._]+)|(rv:[\d._]+)/g);
            } else if (supporter === "edge") {
                supporterVs = testVs(/edge\/[\d._]+/g);
            }
            window.parse_data.supporterVs = supporterVs;
        } catch (e) {
            window.parse_cache_error.browserType = e.toString();
        }
    }

    const _ParseUID = "_ParseUID";
    try {
        window.parse_data = {
            domain: window.location.origin,
            userID: ParseGetCookie() || ParseSetCookie(),
            gid: ParseGetCookie("_gid") || "",
            ga: ParseGetCookie("_ga") || "",
            href: window.location.href,
            ua: navigator.userAgent,
            language: navigator.language,
            version: "1.17",
            website: window.location.host,
            created_at: "",
            date: "",
            downlink: 0,
            clickHref: "",
            effectiveType: "",
            jsError: "",
            buy_product: "",
            quantity: "",
            event: "",
            hrefType: "",
            clickType: "",
            system: "",
            systemVs: "",
            platform: "",
            engine: "",
            supporter: "",
            engineVs: "",
            supporterVs: "",
            isIncognito: "",
            isIncognitoText: "",
            history: document.referrer,
            a_text: "",
            a_title: "",
            analyzeSource: "",
            downloadFile: "",
            downloadType: "",
            downloadQuality: "",
            analyzeHref: "",
            analyzeResult: "",
        };
        window.parse_cache_error = {};

        ParseBrowserType();
        ParseSetCreateTime();

        isIncognito().then(function (result) {
            window.parse_data.isIncognito = result[0];
            window.parse_data.isIncognitoText = result[1];
        });

        window.parse_data.event = "loadPage";
        if (/(.*)(\/store\/)(.*)/.test(window.location.pathname)) {
            window.parse_data.hrefType = "buy";
        } else if (
            /(.*)(\/installation\.html)/.test(window.location.pathname)
        ) {
            window.parse_data.hrefType = "install";
        } else if (
            /(.*)(\/uninstallation\.html)/.test(window.location.pathname)
        ) {
            window.parse_data.hrefType = "uninstall";
        } else if (
            /(.*)(\/registration\.html)/.test(window.location.pathname)
        ) {
            window.parse_data.hrefType = "register";
        }
        window.ParseSendInfo();
    } catch (e) {
        window.parse_cache_error.init = e.toString();
    }
};

export function onClickPage(ele, name, leftClick = true) {
    try {
        const base = window.location.origin;

        window.parse_data.href = base + router.currentRoute.fullPath;
        window.parse_data.history = base + window.custom_history.fullPath;
        window.parse_data.clickHref = `${name}`;
        window.parse_data.event = "clickLink";
        window.parse_data.clickType = leftClick ? "left" : "right";
        window.parse_data.a_text = ele.text
            ? ele.text
            : ele.target.innerHTML.trim() || "";
        window.parse_data.a_title = ele.text
            ? ele.text
            : ele.target.title || "";

        // 暂时没有下载软件的，需要再兼容
        if (/(.exe|.pkg|.dmg)$/.test(name)) {
            window.parse_data.hrefType = "software";
        }
        if (window.parse_data.supporter == "firefox") {
            setTimeout(function () {
                window.ParseSendInfo();
            }, 100);
        } else {
            window.ParseSendInfo();
        }
    } catch (e) {
        window.parse_cache_error.clickInfo = e.toString();
        window.ParseSendInfo();
    }
}
