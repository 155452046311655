<template>
    <div :style="positionStyle" class="popup-continer">
        <div v-if="isSuccess || isError" class="custom-popup success-popup">
            <img
                loading="lazy"
                :src="
                    require(`@/assets/img/icon/${
                        !isError ? 'success' : 'fail'
                    }.svg`)
                "
                :alt="isError ? 'fail' : 'success'"
            />
            <p class="text">{{ text }}</p>
            <p
                class="p-btn usable"
                @click="
                    $event.stopPropagation();
                    accountPresence ? toLogin($event) : handleOk();
                "
            >
                {{ accountPresence ? $t("login.login") : $t("popup.ok") }}
            </p>
        </div>
        <div v-if="isWarning" class="custom-popup warn-popup">
            <img
                loading="lazy"
                src="@/assets/img/icon/warning.svg"
                alt="warning"
            />
            <p>{{ text }}</p>
            <div>
                <p
                    class="p-btn usable"
                    :class="[
                        customConfirm && customConfirm.type == 'red'
                            ? 'red-btn'
                            : '',
                        isWaiting ? 'loading' : '',
                    ]"
                    @click="handleConfirm"
                >
                    {{
                        isWaiting
                            ? ""
                            : this.customConfirm
                            ? this.customConfirm.text
                            : $t("popup.confirm")
                    }}
                </p>
                <p class="p-btn popup-cancel" @click="handleCancel">
                    {{ $t("popup.cancel") }}
                </p>
            </div>
        </div>
        <div v-if="isStopSub" class="custom-popup stop-popup">
            <label v-for="(o, i) in options" :key="i">
                <input type="radio" :value="o" v-model="selectedReason" />
                <span>{{ o }}</span>
                <input
                    type="text"
                    v-if="selectedReason == o && i != 1"
                    v-model="specificReason"
                />
            </label>
            <p
                class="p-btn usable"
                :class="[
                    canComfirm ? '' : 'disabled',
                    inFeedback ? 'isLoading' : '',
                ]"
                @click="handleSubmit"
            >
                {{ $t("popup.confirm") }}
            </p>
            <p class="p-btn popup-cancel" @click="handleClose">
                {{ $t("popup.cancel") }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "customPopup",
    props: {
        text: {
            type: String,
            require: true,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        isSuccess: {
            type: Boolean,
            default: false,
        },
        isWarning: {
            type: Boolean,
            default: false,
        },
        customConfirm: {
            type: Object,
        },
        isWaiting: {
            type: Boolean,
            default: false,
        },
        isStopSub: {
            type: Boolean,
            default: false,
        },
        accountPresence: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            confirmText: "",
            offsetY: 0,
            viewportHeight: 0,
            selectedReason: "",
            specificReason: "",
            options: this.$t("popup.options"),
            inFeedback: false,
        };
    },
    methods: {
        handleOk() {
            this.$parent.isSuccess = false;
            this.$parent.isError = false;
        },
        handleConfirm() {
            this.$parent.handleConfirm();
        },
        handleCancel() {
            this.$parent.isWarning = false;
        },
        handleClose() {
            this.$parent.isStopSub = false;
        },
        handleSubmit() {
            this.$parent.handleSubmit();
        },
        toLogin(e) {
            this.$parent.isError = false;
            this.$navigateTo(e, "/login");
        },
    },
    computed: {
        ...mapGetters(["screenWidth"]),
        popupLeft() {
            const l = this.screenWidth > 850 ? "50vw" : 0;
            return l;
        },
        popupTop() {
            const t =
                (this.screenWidth > 850
                    ? this.viewportHeight * 0.5
                    : this.viewportHeight) + this.offsetY;
            return t;
        },
        positionStyle() {
            if (this.screenWidth < 550) {
                return `left:0px; top:auto; bottom:0; position:fixed; transform: translate(0, 0);`;
            } else {
                return "left:50%; top:50%; position:fixed;";
            }
        },
        canComfirm() {
            return this.selectedReason == this.options[1]
                ? true
                : this.specificReason != "";
        },
        reason() {
            return `事件：暂停订阅，原因：${this.selectedReason}, 具体：${this.specificReason}`;
        },
    },
    mounted() {
        window.addEventListener("message", (e) => {
            const d = e.data;
            switch (d.event) {
                case "setYoffset":
                    this.offsetY = d.offsetY;
                    this.viewportHeight = d.height;
                    break;
            }
        });
    },
    watch: {
        selectedReason() {
            this.specificReason = "";
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common.scss";

.popup-continer {
    z-index: 100;
    transform: translate(-50%, -50%);
    position: absolute;
    margin: 0 !important;
}

.custom-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    padding: 40px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.4),
        0px 0px 1px 0px rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(50px);
    box-sizing: content-box;
    @include columnGap(30px);

    img {
        width: 80px;
        height: 80px;
    }

    > div {
        width: 100%;

        p:first-child {
            margin-bottom: 10px;
        }
    }
    .p-btn {
        width: 100%;
    }

    .text {
        text-align: center;
        font-size: 20px;
        line-height: 120%;
        width: 100%;
        overflow: hidden;
    }
    .popup-cancel {
        background: #f2f2f2;

        &:hover {
            background: #ccc;
            color: #000;
        }
    }
}

.warn-popup {
    .red-btn {
        background: #d80000;
        color: #fff;
        &:hover {
            background: #000;
        }
    }
    .p-btn.loading {
        position: relative;
        font-size: 0;
        &::before {
            position: absolute;
            content: "";
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid #fff;
            border-top-color: transparent;
            animation: circle infinite 0.75s linear;
        }
    }
}
.stop-popup {
    display: flex;
    align-items: flex-start;

    label {
        display: flex;
        align-items: center;
        @include rowGap(10px);
        cursor: pointer;
        width: 100%;
        height: 30px;
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 30px;
        }
        span {
            flex-shrink: 0;
        }
    }
    input[type="radio"] {
        width: 20px;
        accent-color: #6086e8;
        flex-shrink: 0;
        cursor: pointer;

        &:focus {
            border: none;
            outline: none;
        }
    }
    input[type="text"] {
        border-width: 0 0 1px 0;
        border-radius: 0px;
        padding: 2px;
        border-color: #000;
        flex-grow: 1;
        height: 30px !important;
        &:focus {
            outline: none;
        }
    }
    .p-btn {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    .popup-continer {
        position: fixed;
        transform: translate(0, calc(-100% - 50px));
    }
    .custom-popup {
        width: 100vw;
        border-radius: 20px 20px 0px 0px;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4),
            0px 0px 1px 0px rgba(0, 0, 0, 0.75);
        padding: 40px 20px;
        box-sizing: border-box;
    }
}
</style>
