import Vue from "vue";
import VueRouter from "vue-router";
import "element-ui/lib/theme-chalk/index.css";
// home
import dmHome from "./views/dmHome.vue";

// vh
import vhPage from "./views/vhPage/index.vue";
import loginContainer from "./views/vhPage/loginContainer.vue";

// personal
import Personal from "./views/personal/index.vue";
import ManageSubscription from "./views/personal/manageSubscription.vue";
import orderComplete from "./views/orderComplete.vue";
import receipt from "./views/personal/receipt.vue";
import addNewCard from "./views/personal/addNewCard.vue";

// dm
import dmLoginModule from "./views/LoinIndex.vue";

Vue.use(VueRouter);

import { getCookie } from "@/utils/operateCookie.js";

const routes = [
    // vh
    {
        path: "/vhpage",
        name: "vhPage",
        component: vhPage,
        children: [
            {
                path: "personal",
                component: Personal,
            },
            {
                path: "login",
                component: loginContainer,
                props: { name: "Login" },
            },
            {
                path: "register",
                component: loginContainer,
                props: { name: "Register" },
            },
            {
                path: "forget-password",
                component: loginContainer,
                props: { name: "Forget" },
            },
            {
                path: "reset-password/:other?",
                component: loginContainer,
                props: { name: "Update" },
            },
            {
                path: "manage-subscription/:id",
                name: "vhManageSubscription",
                component: ManageSubscription,
            },
            {
                path: "order-complete",
                name: "vhOrderComplete",
                component: orderComplete,
            },
            {
                path: "add-new-card/:source",
                name: "vhAddNewCard",
                component: addNewCard,
            },
            {
                path: "receipt/:id",
                name: "vhViewReceipt",
                component: receipt,
            },
        ],
    },
    // dm
    {
        path: "/",
        name: "dmHome",
        component: dmHome,
        redirect: getCookie("_token") ? "/personal" : "/login",
        children: [
            {
                path: "personal",
                name: "personal",
                component: Personal,
            },
            {
                path: "login",
                component: dmLoginModule,
                props: { name: "login" },
            },
            {
                path: "register",
                component: dmLoginModule,
                props: { name: "register" },
            },
            {
                path: "reset-password",
                component: dmLoginModule,
                props: { name: "resetPassword" },
                meta: { hadSend: false },
                children: [
                    {
                        path: ":other",
                        meta: { hadSend: true },
                    },
                ],
            },
            {
                path: "manage-subscription/:id",
                name: "dmManageSubscription",
                component: ManageSubscription,
            },
            {
                path: "order-complete",
                name: "dmOrderComplete",
                component: orderComplete,
            },
            {
                path: "add-new-card/:source",
                name: "dmAddNewCard",
                component: addNewCard,
            },
            {
                path: "receipt",
                name: "dmViewReceipt",
                component: receipt,
            },
        ],
    },
    {
        path: "/admin-cart",
        name: "adminCart",
        component: () => import("./views/admin.vue"),
    },
    {
        path: "/admin/query-user-subcription",
        name: "queryUserSubcription",
        component: () => import("./views/queryUserSubcription.vue"),
        meta: { isAdmin: true },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("./views/notFound.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    window.custom_history = from;
    next();
});

export default router;
