var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-continer",style:(_vm.positionStyle)},[(_vm.isSuccess || _vm.isError)?_c('div',{staticClass:"custom-popup success-popup"},[_c('img',{attrs:{"loading":"lazy","src":require(`@/assets/img/icon/${
                    !_vm.isError ? 'success' : 'fail'
                }.svg`),"alt":_vm.isError ? 'fail' : 'success'}}),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]),_c('p',{staticClass:"p-btn usable",on:{"click":function($event){$event.stopPropagation();
                _vm.accountPresence ? _vm.toLogin($event) : _vm.handleOk();}}},[_vm._v(" "+_vm._s(_vm.accountPresence ? _vm.$t("login.login") : _vm.$t("popup.ok"))+" ")])]):_vm._e(),(_vm.isWarning)?_c('div',{staticClass:"custom-popup warn-popup"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/img/icon/warning.svg"),"alt":"warning"}}),_c('p',[_vm._v(_vm._s(_vm.text))]),_c('div',[_c('p',{staticClass:"p-btn usable",class:[
                    _vm.customConfirm && _vm.customConfirm.type == 'red'
                        ? 'red-btn'
                        : '',
                    _vm.isWaiting ? 'loading' : '',
                ],on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.isWaiting ? "" : this.customConfirm ? this.customConfirm.text : _vm.$t("popup.confirm"))+" ")]),_c('p',{staticClass:"p-btn popup-cancel",on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t("popup.cancel"))+" ")])])]):_vm._e(),(_vm.isStopSub)?_c('div',{staticClass:"custom-popup stop-popup"},[_vm._l((_vm.options),function(o,i){return _c('label',{key:i},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedReason),expression:"selectedReason"}],attrs:{"type":"radio"},domProps:{"value":o,"checked":_vm._q(_vm.selectedReason,o)},on:{"change":function($event){_vm.selectedReason=o}}}),_c('span',[_vm._v(_vm._s(o))]),(_vm.selectedReason == o && i != 1)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.specificReason),expression:"specificReason"}],attrs:{"type":"text"},domProps:{"value":(_vm.specificReason)},on:{"input":function($event){if($event.target.composing)return;_vm.specificReason=$event.target.value}}}):_vm._e()])}),_c('p',{staticClass:"p-btn usable",class:[
                _vm.canComfirm ? '' : 'disabled',
                _vm.inFeedback ? 'isLoading' : '',
            ],on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t("popup.confirm"))+" ")]),_c('p',{staticClass:"p-btn popup-cancel",on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t("popup.cancel"))+" ")])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }